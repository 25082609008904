module.exports = {
  comm: {
    home: 'Home',
    pricing: 'Prijzen',
    blog: 'Stemklonen & API',
    language: 'Taal:',
    select_language: 'selecteer taal',
    txt2voice: 'Tekst naar spraak',
    voice2txt: 'Spraak naar tekst',
    voiceclone: 'Stem klonen',
    video2txt: 'Video naar tekst',
    footer_help: 'Hulp nodig? Stuur ons een e-mail:',
    footer_mail: 'tiktokaivoicetool@gmail.com',
    login: 'Inloggen',
    login_desc: 'Log in om toegang te krijgen tot je TikTok Stemgenerator account',
    logout: 'Uitloggen',
  },
  txt2voice: {
    title: 'TikTok Stemgenerator: Maak grappige AI TikTok stemmen',
    description: 'Genereer en download gratis grappige AI TikTok stemmen, zoals de stem van Jessie, de stem van C3PO, de stem van ghostface...',
    keywords: 'TikTok Stemgenerator, AI TikTok Stem, Tik Tok Stemgenerator',
    main_title_p01: 'TikTok Stemgenerator',
    main_title_p02: 'Maak grappige TikTok stemmen: Jessie stem, C3PO stem, ghostface stem',
    main_textarea_holder: 'Typ of plak tekst hier',
    main_genvoice: 'genereer',
    main_generating: 'aan het genereren',
    main_input_empty: 'typ of plak tekst a.u.b.',
    daily_usage_limit_msg: '1) De dagelijkse maximale gebruikslimiet is bereikt. Kom morgen terug. 2) Als je dringend meer gebruik nodig hebt, neem dan contact met ons op via e-mail.',
    text_max_prompt1: 'Beperk het aantal woorden tot',
    text_max_prompt2: 'woorden of minder!',
    popup_nologin_title: 'Upgrade je abonnementsplan',
    popup_nologin_desc: 'Ontgrendel meer stemgeneratietijd door te upgraden naar een premium abonnement.',
    popup_nologin_btntext: 'Verken abonnementsplannen',
    popup_nosub_title: 'Upgrade je abonnementsplan',
    popup_nosub_desc: 'Ontgrendel meer stemgeneratietijd door te upgraden naar een premium abonnement.',
    popup_nosub_btntext: 'Verken abonnementsplannen',
    popup_sublimit_title: 'Abonnement opgebruikt, upgrade alsjeblieft',
    popup_sublimit_desc: 'Verbeter je ervaring met onze premium functies en onbeperkte toegang.',
    popup_sublimit_btntext: 'Ga naar meer kopen',
  },
  pricing: {
    new_features_alert: "📣 We hebben 🎙️ Stemklonen en 🤖 API-diensten gelanceerd voor betalende leden! (Na betaling, e-mail tiktokaivoicetool@gmail.com voor hulp bij deze functies). Voel je ook vrij om ons te e-mailen voor eventuele aanpassingsverzoeken😃",
    pricing_title: 'Van individuele makers tot de grootste ondernemingen, we hebben een plan voor u.',
    pricing_desc: '100+ natuurlijke, mensachtige stemmen. 15+ talen. en bijna de laagste prijzen voor iedereen',
    sub_free_name: 'Gratis',
    sub_free_desc: 'Voor individuen die de nieuwste AI-audio willen uitproberen',
    sub_free_content: [
      '1000 tekens limiet per maand',
      '5 generaties per dag',
    ],
    sub_starter_name: 'Starter',
    sub_starter_desc: 'Voor makers die premium content produceren voor een wereldwijd publiek',
    sub_starter_content: [
      '1 stemkloon',
      '300.000 tekens limiet per maand (ongeveer 7 uur audio)',
      'Onbeperkt downloaden',
      'E-mailondersteuning binnen 72 uur',
      'Prioritaire toegang tot nieuwe stemmen en functies',
    ],
    sub_pro_name: 'Pro',
    sub_pro_desc: 'Voor makers die hun contentproductie uitbreiden',
    sub_pro_content: [
      'API-toegang',
      '3 stemklonen',
      '1.000.000 tekens limiet per maand (ongeveer 24 uur audio)',
      'Onbeperkt downloaden',
      'E-mailondersteuning binnen 48 uur',
      'Prioritaire toegang tot nieuwe stemmen en functies',
    ],
    period_year: 'Jaarlijks',
    period_month: 'Maandelijks',
    period_month_short: 'mnd',
    billed_year: 'Jaarlijks gefactureerd',
    most_popular: 'Meest populair',
    discount_quantity: 'Bespaar $48',
    buy_btntext: 'Aan de slag',
    pay_succ: 'Betaling geslaagd!',
    pay_succ_desc: 'Bedankt voor je aankoop. De transactie is succesvol verwerkt.',
    pay_succ_btntext: 'Begin met het gebruik van TikTok Voice',
    pay_fail: 'Betaling mislukt!',
    pay_fail_desc: "Sorry, we konden je transactie niet verwerken. Probeer het opnieuw of neem contact op met ons ondersteuningsteam (tiktokaivoicetool@gmail.com).",
    pay_fail_btntext: 'Terug naar de homepage',
  },
  setting: {
    setting: 'Instellingen',
    setting_title: 'Beheer hier je account, gebruik en abonnement.',
    basic_info: 'Basisinformatie',
    user_name: 'Naam',
    user_email: 'E-mail',
    user_account: 'Account',
    user_subscript: 'Huidig plan',
    user_usage: 'Gebruik',
    manage_subscript: 'Beheer abonnement',
    use_limit_promote: "Je hebt 5000 tekens van je maandelijkse limiet van 10000 tekens gebruikt.",
    not_logged_in: 'Je bent niet ingelogd',
    go_to_login: 'Ga naar inloggen >>',
  },
  login: {
    title: "TikTok Stemgenerator",
    subtitle: "Log in om toegang te krijgen tot je TikTok Stemgenerator account",
    alreadyLoggedIn: "Je bent al ingelogd",
    goToHome: "Ga naar de homepage"
  },
  faq: {
    quest1: 'TikTok Stemgenerator - De beste AI TikTok stemtool',
    answer1_1: 'Het is een AI TikTok stemgenerator-tool ontwikkeld door de nieuwste tts TikTok-technologie, het kan TikTok-stemmen genereren zoals de stem van Jessie (vrouwelijke stem), de stem van Siri, de stem van ghostface, de stem van C3PO (robotstem), de diepe stem (verteller), de stem van Skye, de warme stem, de stem van Bestie, de heldenstem (stem van Chiris), de empathische stem, de serieuze stem, de stem van Joey, de stem van Stitch, de stem van Stormtrooper (Star Wars), de stem van Rocket (Guardians of the Galaxy).',
    answer1_2: 'AI TikTok-stemmen die binnenkort ondersteund worden, zijn onder andere: de stem van Derek, de stem van Trickster, de stem van Austin Butler, de stem van een nieuwsreporter, de stem van Adam, de stem van een hond, de stem van Miley Cyrus, de stem van Alexa, de stem van een alien, de stem van een dier, de stem van een baby, de stem van een computer, de stem van een chipmunk, de stem van een echo, de stem van npr.',
    answer1_3: 'Bovendien ondersteunt de TikTok stemgenerator-tool verschillende andere talen, waaronder Chinees, Japans, Koreaans, Vietnamees, Thais, Hindi, Perzisch, Russisch, Duits, Frans, Roemeens, Tsjechisch, Spaans, Portugees, Bengaals, Italiaans, Arabisch, Urdu, Traditioneel Chinees en Maleis.',
    answer1_4: 'Als je dringend een specifieke stem nodig hebt, stuur me dan een e-mail.',

quest2: 'Wat zijn de voordelen van de TikTok Stemgenerator?',
    answer2_1: "- De TikTok stemgenerator-tool kan verschillende soorten stemmen genereren, die vaak worden gebruikt in TikTok-video's.",
    answer2_2: '- Het is de nieuwste AI stemgenerator die tts TikTok-stemmen kan genereren die lijken op menselijke stemmen.',
    answer2_3: "- Het is handiger voor het bewerken van video's op een pc.",
    answer2_4: '- Je kunt sommige stemmen gebruiken die momenteel niet beschikbaar zijn in de tts-app van TikTok.',

quest3: 'Hoe gebruik je de TikTok Stemgenerator?',
    answer3_1: 'De TikTok stemgenerator is heel eenvoudig te gebruiken:',
    answer3_2: '- Kies de taal en het accent van de stem.',
    answer3_3: '- Typ de tekst die moet worden omgezet in spraak in het invoervak.',
    answer3_4: '- Druk op de knop genereren en wacht een paar seconden.',
    answer3_5: '- Speel of download de AI TikTok-stem.',

quest4: 'Wat zijn de bekendste en grappigste TikTok-stemmen?',
    answer4_1: 'De bekendste mannelijke TikTok-stem is de diepe stem, officieel de verteller genoemd.',
    answer4_2: "Voor de grappigste TikTok-stem raad ik persoonlijk de stem van ghostface en de stem van C3PO aan. Deze stemmen worden vaak gebruikt voor het nasynchroniseren van grappige TikTok-video's omdat ze gemakkelijk te herkennen zijn en snel de aandacht van de kijkers kunnen trekken.",

quest5: 'Wat zijn de vervelendste TikTok-stemmen?',
    answer5_1: 'Ik denk dat er eigenlijk geen echt "vervelende" TikTok-stem is.',
    answer5_2: "Het hangt meer af van de context en de inhoud waarin de stem wordt gebruikt, wat sommige mensen ongemakkelijk kan maken. Bijvoorbeeld, sommige mensen vinden de stem van Jessie vervelend omdat deze in een bepaalde periode te vaak op TikTok verscheen. Door zijn toon en kwaliteit is het echter eigenlijk een fantastisch TikTok-stemeffect. Bovendien is de TikTok-stemactrice erachter zeer professioneel en plaatst ze ook haar eigen video's op TikTok.",
    answer5_3: 'Daarom raad ik iedereen aan om hun zorgen en vooroordelen opzij te zetten, de stemmen van deze tool meerdere keren te beluisteren en geduldig het TikTok-stemeffect te vinden dat het beste bij hen past.',

quest6: 'Hoe gebruik je de TikTok Stemgenerator om de stem van Jessie te genereren?',
    answer6_1: '- Kies in het eerste vervolgkeuzemenu van de TikTok stemgenerator-tool voor Engels (VS), kies daarna in het andere vervolgkeuzemenu voor de stem van Jessie (vrouwelijke stem).',
    answer6_2: '- Typ de tekst in het invoervak en klik op de knop genereren.',
    answer6_3: '- Wacht een paar seconden of tot tien seconden, je hoort de AI-stem. Zoek in de werkbalk naar de knop met een pijl naar beneden en klik erop om te downloaden.',

quest7: 'Hoe gebruik je de TikTok Stemgenerator om de stem van C3PO te genereren?',
    answer7_1: '- Kies in het eerste vervolgkeuzemenu van de TikTok stemgenerator-tool voor Engels (VS), kies daarna in het andere vervolgkeuzemenu voor de stem van C3PO (robotstem).',
    answer7_2: '- Typ de tekst in het invoervak en klik op de knop genereren.',
    answer7_3: '- Wacht een paar seconden of tot tien seconden, je hoort de AI-stem. Zoek in de werkbalk naar de knop met een pijl naar beneden en klik erop om te downloaden.',

quest8: 'Hoe gebruik je de TikTok Stemgenerator om de stem van ghostface te genereren?',
    answer8_1: '- Kies in het eerste vervolgkeuzemenu van de TikTok stemgenerator-tool voor Engels (VS), kies daarna in het andere vervolgkeuzemenu voor de stem van ghostface.',
    answer8_2: '- Typ de tekst in het invoervak en klik op de knop genereren.',
    answer8_3: '- Wacht een paar seconden of tot tien seconden, je hoort de AI-stem. Zoek in de werkbalk naar de knop met een pijl naar beneden en klik erop om te downloaden.',

quest9: 'Hoe gebruik je de TikTok Stemgenerator om de stem van Siri te genereren?',
    answer9_1: '- Kies in het eerste vervolgkeuzemenu van de TikTok stemgenerator-tool voor Engels (VS), kies daarna in het andere vervolgkeuzemenu voor de stem van Siri.',
    answer9_2: '- Typ de tekst in het invoervak en klik op de knop genereren.',
    answer9_3: '- Wacht een paar seconden of tot tien seconden, je hoort de AI-stem. Zoek in de werkbalk naar de knop met een pijl naar beneden en klik erop om te downloaden.',

quest10: 'Hoe voeg je TikTok-stemeffecten toe aan mijn TikTok-video?',
    answer10_1: 'Als je wilt weten hoe je de officiële TikTok-stemmen op TikTok kunt gebruiken, zal ik binnenkort een speciale blogpost over dit onderwerp schrijven.',
    answer10_2: 'Hier leg ik uit hoe je je stem op TikTok kunt publiceren na het genereren en downloaden van de TikTok stemgenerator.',
    answer10_3: '1. Als je de TikTok-stem op je pc hebt gegenereerd en deze wilt uploaden naar TikTok of een andere video-editing-app op je telefoon, moet je het stembestand naar je telefoon overzetten. Voor iPhones kun je airDrop gebruiken voor de overdracht. Voor Android-telefoons ben ik niet zo bekend met dit proces, maar je zult zeker manieren en tools vinden om dit te doen.',
    answer10_4: '2. Zodra het stembestand naar je telefoon is overgezet, kun je TikTok openen:',
    answer10_5: '- Tik op de "+"-knop onderaan de interface, selecteer de video op je telefoon.',
    answer10_6: '- Nadat de video is geüpload, zoek je naar een vierkant pictogram aan de rechterkant van het scherm om naar de bewerkingspagina te gaan.',
    answer10_7: '- Zoek onderaan de pagina naar de knop "stem toevoegen" en tik erop om het zojuist overgezette stembestand te selecteren.',
    answer10_8: '- In deze interface kun je enkele aanpassingen aan de video maken en vervolgens op de knop in de rechterbovenhoek tikken om deze te publiceren.',

quest11: 'Is TikTok Voice gratis?',
    answer11: 'Ja, het is een gratis AI TikTok stemgenerator-tool van tekst naar spraak.',

quest12: 'Waar kan ik een populaire TikTok-stem genereren in mijn TikTok-video?',
    answer12: 'Je kunt https://tiktokvoice.net/ bezoeken om AI TikTok-stemmen toe te voegen aan je video.'
  },
  // host: 'http://tiktokvoice.net'
  host: 'https://tiktokvoice.net'
}